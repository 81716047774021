import './App.css';
import axios from 'axios';
import { Grid, Typography, CircularProgress, TextField, Button,
  FormGroup, FormControlLabel, Checkbox, FormControl, 
  Radio, RadioGroup, Divider} from '@mui/material';
import {  BrowserRouter, Routes, Route} from "react-router-dom";
import React, { useState, useEffect } from 'react'
import {send_palserverAPI_request} from './send_serverAPI_request';
import { v4 as uuidv4 } from 'uuid';


/*
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
        */

import QRCode from "qrcode";

const QUERY1_SRC = "https://fi.research.net/r/Y2WT6MK";
//const QUERY1_SRC = "http://127.0.0.1:6100";
const QUERY2_SRC = "https://fi.research.net/r/PCMJB7Z";

export default function App() {

  const [failed, setFailed] = useState(false)
  const [qrInfo, setQrInfo] = useState({});
  const [kiitosInfo, setKiitosInfo] = useState(undefined);
  const [ip,setIP] = useState('');
  const [lottery, setLottery] = useState('yes');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [answer2nd, setAnswer2nd] = useState('yes');
  const [emailSentCount, setEmailSentCount] = useState(0);
  const [moveTo2nd, setMoveTo2nd] = useState(false);

  const BASE_URL = window.location.origin + "/";


  const serverReqErrorHandler = (errorid, code) => {
    console.log('App.js: ERROR: ', errorid, code);
    setFailed(true);
  };

  const getIPData = async()=>{
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log("IP-osoite:", res.data.IPv4);
    setIP(res.data.IPv4)
}

  // This hook is listening an event that came from the Iframe
  useEffect(() => {
    const handler = (ev) => {


      if (typeof ev.data !== 'object') return
      if (!ev.data.type) return
      if (ev.data.type !== 'button-click') return
      if (!ev.data.message) return

      let key = uuidv4();
      let baseurl = BASE_URL + "kiitos/";

      getIPData()

      window.removeEventListener('message', handler)
      QRCode.toDataURL(baseurl + key)
      .then(url => {
        console.log('generoitu url: ', url); 
      
        send_palserverAPI_request(
        'post',
        '/survey-api/surveydones',
        '',
        {baseurl: baseurl,
         key: key, 
         qrSrcUrl: url,
         email: '',
         tel: '',
         answer2nd: ''},
        'ERROR_QR_REG',
        serverReqErrorHandler,
        response => {
          console.log("Serveriltä vastaus!!!", response)
          setQrInfo(response);
        }
        )
    })
    .catch(err => {console.log(err)})
    }

    window.addEventListener('message', handler)

  }, [])

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value);
    } else if (event.target.id === "tel") {
      setTel(event.target.value);
    } 
  };

  const handleLotteryRadioGroupChange = (event) => {
    setLottery(event.target.value);
  };

  const handleRadioGroupChange = (event) => {
    setAnswer2nd(event.target.value);
  };

  const handleClick = () => {
    console.log("handleClick", email, tel )
    send_palserverAPI_request(
      'patch',
      '/survey-api/surveydones/' + qrInfo.id,
      '',
      {email: email, tel:tel, answer2nd: answer2nd},
      'ERROR_CLICK_SEND',
      serverReqErrorHandler,
      response => {
        console.log("Serveriltä vastaus!!!", response)
        if (answer2nd === "no" || answer2nd === "later") {
          window.location.replace("https://www.haggman.fi");
        } else {
          setMoveTo2nd(true);
        }
      }
      )
  }

  const handleQrClick = (event) => {
    send_palserverAPI_request(
      'post',
      '/survey-api/sendemail/' + qrInfo.id,
      '',
      {email: email},
      'ERROR_EMAIL_SEND_REG',
      serverReqErrorHandler,
      response => {
        console.log("Serveriltä vastaus!!!", response)
        setEmailSentCount(emailSentCount + 1);
      }
      )
  }

  const handleUseCodeClick = () => {

    send_palserverAPI_request(
      'patch',
      '/survey-api/surveydones/' + qrInfo.id,
      '',
      {used: true},
      'ERROR_MARK_USED_REG',
      serverReqErrorHandler,
      response => {
        console.log("Serveriltä vastaus!!!", response)
        setQrInfo(response);
        setKiitosInfo("USED")
      }
      )
  }


  if (window.location.href === BASE_URL + 'surveydone') {
    window.parent.postMessage(
      {
        type: 'button-click',
        message: "terve",
      },
      '*'
    )
  }

  let kiitosBgColor = 'white';
  let kiitosText = '';
  if (kiitosInfo === undefined &
      window.location.href.startsWith(BASE_URL + 'kiitos')) {
    const windLoc = window.location.href.split("/kiitos/")


    send_palserverAPI_request(
      'get',
      '/survey-api/surveydonesbykey/' + windLoc[1],
      '',
      {},
      'ERROR_QR_GET',
      serverReqErrorHandler,
      response => {
        console.log("Serveriltä vastaus QR GETTIINN!!!", response)
        setKiitosInfo(response.status);
        if (response.status !== "INVALID") {
          setQrInfo(response.surveydone)
        }
      }
    )
  } else if (kiitosInfo !== undefined &
    window.location.href.startsWith(BASE_URL + 'kiitos')) {
    if (kiitosInfo === "INVALID") {
      kiitosBgColor = 'red';
      kiitosText = "Pahoittelut, mutta tämä kuponki ei ole validi"  
    } else if (kiitosInfo === "USED") {
      kiitosBgColor = 'orange';  
      kiitosText = "Tämä kuponki on käytetty" 
    } else {
      kiitosBgColor = 'green';
      kiitosText = "Tämä on validi kuponki" 
    }
  }

  let querySrc = QUERY1_SRC;
  if (moveTo2nd) {querySrc = QUERY2_SRC}


  console.log("APP.js RENDER:", window.location, qrInfo, kiitosInfo)

  return (
      <div style={{height:"99vh", width:"99vw"}}>
      <BrowserRouter>
        <Routes>
          <Route path="/haggman" element={
              (!(qrInfo.id === undefined) && moveTo2nd === false) ? 
              <Grid container direction="row" 
                    justifyContent="space-around"
                    style={{height:"100%", width:"100%", padding:20}}>            
                <Grid item xs={12} style={{width:"100%", maxWidth:1000}}>
                  <Grid container direction="column" 
                        justifyContent="space-around"
                        style={{paddingTop:10, height:"100%"}}>
                    {/*<Grid item style={{padding:10, backgroundColor:"red"}}>
                      <img alt="HaggmanLogo" src="https://www.haggman.fi/wp-content/themes/haggman/img/logo.png"></img>
          </Grid>*/}
                    <Grid item style={{padding:10}}>
                      <Typography align="center" variant="h5"
                        style={{padding:20}}>Iso kiitos vastauksestasi!</Typography>
                    </Grid>
                    <Grid item style={{backgroundColor:"white", paddingTop:10}}>
                      <Typography >
                      Arvomme kaikkien vastanneiden kesken ...
                      </Typography>
                    </Grid >
                    <Grid item style={{backgroundColor:"white", paddingTop:10}}>
                      <Typography >
                      Arvonnan säännöt ...
                      </Typography>
                    </Grid >
                    <Grid item style={{backgroundColor:"white", paddingTop:20}}>
                      <Typography sx={{fontWeight: 'bold'}}>
                      Haluatko osallistua arvontaan?
                      </Typography>
                    </Grid >
                    <FormControl style={{margin:10}}>
                      <RadioGroup
                        aria-labelledby="lottery-radio-buttons-group-label"
                        name="lottery-radio-buttons-group"
                        value={lottery}
                        onChange={handleLotteryRadioGroupChange}
                      >
                        <FormControlLabel value="yes" control={<Radio />} label="Kyllä, osallistun arvontaan" />
                          <Grid item style={{marginLeft:30}}>
                            <Grid container direction="column" 
                                  justifyContent="flex-start"
                                  alignItems="left">
                              <Grid item style={{backgroundColor:"white", paddingTop:20}}>
                                <Typography>
                                  Yhteystiedot palkinnosta ilmoittamista varten
                                </Typography>
                              </Grid >
                              <TextField style={{width:"100%", margin:5}} 
                                id="email" 
                                label="e-mail" variant="standard"
                                value={email}
                                onChange={handleChange}/>
                              <TextField style={{width:"100%", margin:5}} 
                                id="tel" 
                                label="Puhelinnumero" variant="standard"
                                value={tel}
                                onChange={handleChange}/>
                              <Typography variant="body2" style={{paddingTop: 20}} >
                                Vastaajien henkilötietojen käsittelyn tarkoituksena on arvontapalkinnoista sekä mahdollisista muista Häggmanin palvelun kehittämiseen liittyvistä jatkokyselyistä ilmoittaminen. 
                                Henkilötietoja ei käytetä muuhun suoramarkkinointiin. Lue lisää
                              </Typography>
                              <FormGroup style={{margin:10}}>
                                <FormControlLabel control={<Checkbox/>} label="Hyväksyn henkilötietojeni käsittelyn tietosuojaselosteen mukaisesti" />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        <FormControlLabel value="no" control={<Radio />} label="En osallistu arvontaan" />
                        
                      </RadioGroup>
                    </FormControl>

                    
                    <Divider light style={{padding:20}} />
                    <Grid item style={{backgroundColor:"white", paddingTop:40}}>
                      <Typography sx={{fontWeight: 'bold'}}>
                       Haluaisitko vastata vielä toiseen, ihmisten yleisiä kulutustottumuksia ja -asenteita selvittävään kyselyyn?
                      </Typography>
                    </Grid >
                    <Grid item style={{backgroundColor:"white", paddingTop:10}}>
                      <Typography >
                        Kyselyyn 12.3.2023 mennessä vastanneiden kesken{' '} 
                        <span style={{fontWeight:'bold', color:"#548235"}}> 
                           arvotaan kaksi (2) 50€:n arvoista Häggmanin lahjakorttia.
                        </span>
                        
                      </Typography>
                    </Grid >
                    <Grid item style={{backgroundColor:"white", paddingTop:10, paddingBottom:10}}>
                      <Typography >
                        Vastaamiseen kuluu aikaa arviolta noin 15min. Kysely on yhteistyökumppanimme järjestämä. 
                      </Typography>
                    </Grid >
                    <FormControl style={{margin:10}}>
                      <RadioGroup
                        aria-labelledby="answer2nd-radio-buttons-group-label"
                        name="answer2nd-radio-buttons-group"
                        value={answer2nd}
                        onChange={handleRadioGroupChange}
                      >
                        <FormControlLabel value="yes" control={<Radio />} label="Kyllä, vastaan heti" />
                        <FormControlLabel value="later" control={<Radio />} label="Myöhemmin" />
                        <FormControlLabel value="no" control={<Radio />} label="Ei" />
                    </RadioGroup>
                    </FormControl>
                    <Grid item style={{padding:10, marginBottom:20}}>
                      <Grid container direction="column" 
                            justifyContent="flex-start"
                            alignItems="center">
                        <Button variant="contained"
                          disabled={emailSentCount > 2 ? true : false}
                          onClick={handleClick}>Lähetä</Button>
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid container direction="column" justifyContent="space-around"
                  style={{width:"100%", height:"100%"}}>               
                <Grid style={{height:"100%", width:"100%"}}>
                  <iframe height={"100%"} width={"100%"}
                          style={{border:'none'}} 
                          src={querySrc} ></iframe>
                </Grid>
              </Grid>
              }
          />
          <Route path="/kiitos/*" 
            element={
            <Grid container direction="column" justifyContent="center"
                alignItems="center"
                style={{height:"100%", 
                        backgroundColor: kiitosBgColor, 
                        color: "white"}}> 
              <Grid>
                <Typography variant="h4">{kiitosText}</Typography>
              </Grid>
              {(kiitosInfo === "VALID" & qrInfo.used === false) ? 
              <Grid style={{paddingTop:20}}>
                <Button variant="contained"
                          disabled={emailSentCount > 2 ? true : false}
                          onClick={handleUseCodeClick}>KUITTAA KÄYTETYKSI</Button>
              </Grid>
              :
              null}
            </Grid>
          } />
          <Route path="/surveydone" element={            
            <Grid container direction="column" justifyContent="center"
               alignItems="center"
               style={{height:"100%"}}> 
              <Grid>
                <CircularProgress />
              </Grid>
            </Grid>
          }/>
        </Routes>
      </BrowserRouter>
      </div>
  );
}

